<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Active Orders Filter -->
            <div class="col-lg-3 mb-2">
              <div class="vertical-center flex-gap-1">
                <CSwitch
                  shape="pill"
                  variant="opposite"
                  color="info"
                  :checked="activeOnly"
                  @update:checked="value => { activeOnly = value; inputFilter() }"
                />
                <span class="label">Active Orders Only</span>
              </div>
            </div>

            <!-- Uninvoiced Orders Filter -->
            <div class="col-lg-3 mb-2">
              <div class="vertical-center flex-gap-1">
                <CSwitch
                  shape="pill"
                  variant="opposite"
                  color="info"
                  :checked="uninvoicedOnly"
                  @update:checked="value => { uninvoicedOnly = value; inputFilter() }"
                />
                <span class="label">Uninvoiced Orders</span>
              </div>
            </div>

            <!-- Restaurant Orders Filter -->
            <div class="col-lg-3 mb-2">
              <div class="vertical-center flex-gap-1">
                <CSwitch
                  shape="pill"
                  variant="opposite"
                  color="info"
                  :checked="restaurantOnly"
                  @update:checked="value => { restaurantOnly = value; inputFilter() }"
                />
                <span class="label">Restaurant Orders</span>
              </div>
            </div>
          </div>

          <!-- Search -->
          <!-- <div class="row">
            <div class="col-lg-12 mb-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search orders..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div> -->
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results available!',
        noItems: 'No orders found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- Actions -->
      <template #actions="{ item, index }">
        <td>
          <CDropdown v-if="item.is_editable">
            <template #toggler>
              <CButton color="ghost" size="sm" class="p-0">
                <CIcon name="cil-options" />
              </CButton>
            </template>
            <CDropdownItem :to="{ name: 'Edit Merchandise Order', params: { id: item.id } }">
              <CIcon name="cil-pencil" class="mr-2" /> Edit Order
            </CDropdownItem>
            <CDropdownItem v-if="!item.canceled_at" @click="cancelOrder(item)">
              <CIcon name="cil-x-circle" class="mr-2" /> Cancel Order
            </CDropdownItem>
            <CDropdownItem @click="deleteOrder(item)">
              <CIcon name="cil-trash" class="mr-2" /> Delete
            </CDropdownItem>
          </CDropdown>
        </td>
      </template>

      <!-- Order ID -->
      <template #id="{ item }">
        <td>
          #{{ item.id }}
        </td>
      </template>

      <!-- Merchant -->
      <template #merchant="{ item }">
        <td>
          <div class="font-weight-bold">{{ item.merchant?.restaurant_name || item.merchant?.first_name }}</div>
          <div class="small text-muted">ID: {{ item.merchant?.id }}</div>
        </td>
      </template>

      <!-- Order Date -->
      <template #order_date="{ item }">
        <td>
          {{ moment(item.order_date).format('LL') }}
        </td>
      </template>

      <!-- Status -->
      <template #status="{ item }">
        <td>
          <CBadge v-if="item.canceled_at && item.cancellation_reason" color="danger"
            v-c-tooltip="{ content: item.cancellation_reason, placement: 'top' }">
            {{ getStatusText(item) }}
          </CBadge>
          <CBadge v-else :color="getStatusColor(item)">
            {{ getStatusText(item) }}
          </CBadge>
        </td>
      </template>

      <!-- Amount -->
      <template #amount="{ item }">
        <td>
          {{ item.total_amount | toCurrency }}
        </td>
      </template>

    </CDataTable>

    <!-- Pagination -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots="false"
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" />
  </CCardBody>
</template>

<script>
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';

export default {
  name: 'BackendTable',

  data() {
    return {
      loadedItems: [],
      fields: [
        { key: 'actions', label: '', sorter: false, _style: 'min-width: 48px; width: 60px;' },
        { key: "id", label: "ID", _style: "min-width: 48px; width: 60px;" },
        { key: 'merchant', label: 'Merchant', _style: 'min-width: 200px;' },
        { key: 'order_date', label: 'Order Date', _style: 'min-width: 180px;' },
        { key: 'status', label: 'Status', _style: 'min-width: 120px;' },
        { key: 'amount', label: 'Amount', _style: 'min-width: 120px;' }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: 'created_at',
      direction: 'desc',
      search: null,

      // Filters
      activeOnly: false,
      uninvoicedOnly: false,
      restaurantOnly: false,

      loading: false,
    };
  },

  computed: {
    ...mapGetters(['errors']),

    itemsPerPage: {
      get() { return this.items_per_page || parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set(value) { this.items_per_page = value }
    },

    reloadParams() { return [this.activePage]; },

    queryPage() {
      return parseInt(this.$route.query.page) || 1
    },
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Merchandise Orders', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;
    await this.getAllItems();
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      const url = new URL(this.$backend.MERCHANDISE_ORDERS.GET_ALL);
      const params = {
        page: this.activePage,
        itemsPerPage: this.itemsPerPage,
        'order-by': this.orderBy,
        direction: this.direction,
        search: this.search,
        active: this.activeOnly,
        uninvoiced: this.uninvoicedOnly,
        restaurant_only: this.restaurantOnly
      };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      try {
        const { data } = await this.$axios.get(url);

        if (data.meta.current_page > data.meta.last_page) {
          this.activePage = 1;
        } else {
          this.activePage = data.meta.current_page;
          this.pages = data.meta.last_page;
          this.total = data.meta.total;
          this.loadedItems = data.data;
        }
      } catch (error) {
        this.$toast.error('Failed to load orders');
      } finally {
        this.loading = false;
      }
    },

    async deleteOrder(item, index) {
      const confirm = await this.mSwal.fire({
        title: 'Are you sure you want to delete this order?',
        text: 'This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.MERCHANDISE_ORDERS.DELETE.replace('{id}', item.id);

        try {
          await this.$axios.delete(deleteUrl);
          this.$delete(this.loadedItems, index);
          this.$toast.success('Order deleted successfully');
        } catch (error) {
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        }
      }
    },

    async cancelOrder(item) {
      const { value: reason } = await this.mSwal.fire({
        title: 'Cancel Order',
        input: 'text',
        inputLabel: 'Cancellation Reason',
        inputPlaceholder: 'Enter cancellation reason...',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a cancellation reason';
          }
        }
      });

      if (reason) {
        const cancelUrl = this.$backend.MERCHANDISE_ORDERS.CANCEL.replace('{id}', item.id);

        try {
          await this.$axios.patch(cancelUrl, {
            cancellation_reason: reason
          });

          item.canceled_at = new Date();
          item.cancellation_reason = reason;
          this.$toast.success('Order cancelled successfully');
        } catch (error) {
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        }
      }
    },

    getStatusColor(item) {
      if (item.canceled_at) return 'danger';
      if (!item.invoice) return 'warning';
      return 'success';
    },

    getStatusText(item) {
      if (item.canceled_at) return 'Cancelled at ' + this.moment(item.canceled_at).format('D-M-Y');
      if (!item.invoice) return 'Uninvoiced';
      return 'Completed';
    },

    // Filter methods
    inputFilter() {
      this.getAllItems();
    },

    resetFilters() {
      if (this.$store.state.filterShow) {
        this.activeOnly = false;
        this.uninvoicedOnly = false;
        this.restaurantOnly = false;
        this.search = '';
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Table operation methods
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },

    paginationChange(value) {
      this.itemsPerPage = value;
      this.getAllItems();
    },

    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? 'asc' : 'desc') : null;
      this.getAllItems();
    },
  },
};
</script>

<style scoped>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}

.count {
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
}
</style>
